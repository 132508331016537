import React from 'react';
import AppProvider from 'store/provider';
import wrapPageElementWithTransition from 'helpers/wrapPageElement';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUsers,
  faHandHoldingSeedling,
  faSeedling,
  faHandshakeAlt,
  faArrowRight,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faUsers,
  faHandHoldingSeedling,
  faSeedling,
  faHandshakeAlt,
  faArrowRight
);

require('typeface-nunito-sans');

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>;
};

// Page Transitions
export const wrapPageElement = wrapPageElementWithTransition;
